<template>
  <div id="admin-hosts">
    <!-- App Bar -->
    <v-app-bar
      app
      color="white"
      light
      flat
    >
      <v-app-bar-nav-icon
        v-if="!sidebarMenu"
        @click.stop="sidebarMenu = !sidebarMenu"
      />
    </v-app-bar>
    <!-- Sidebar -->
    <v-navigation-drawer 
      v-model="sidebarMenu" 
      app
      floating
      :permanent="sidebarMenu"
      :mini-variant.sync="mini"
    >
      <template #prepend>
        <v-list-item two-line>
          <v-list-item-action>
            <v-icon
              v-if="sidebarMenu"
              @click.stop="sidebarMenu = !sidebarMenu"
            >
              mdi-chevron-left
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <router-link
              to="/"
              class="black--text text-decoration-none"
            >
              <img
                width="50%"
                height="70%"
                src="@/assets/images/logo/with-black.png"
                class="mt-3 ml-3"
              >
            </router-link>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.url"
          active-class="yellow"
          class="opacity-1"
        >
          <v-list-item-icon>
            <v-icon color="light-grey">
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="light-grey">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      
      <template #append>
        <v-list>
          <v-list-item
            active-class="yellow"
            class="opacity-1"
            @click="logout()"
          >
            <v-list-item-icon>
              <v-icon color="light-grey">
                mdi-logout
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="light-grey">
                {{ $t("logout") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <!-- Main Container -->
    <v-container
      class="fill-height dashboard-container"
      fluid
    >
      <v-row
        class="fill-height grey mx-7"
        justify="center"
      >
        <v-flex
          xs12
          md4
          my-5
        >
          <v-card
            class="search-box"
            flat
            tile
          >
            <v-text-field
              solo
              placeholder="Eg. Mick Gordon"
              flat
              tile
              hide-details
              color="black"
              background-color="white"
              prepend-inner-icon="mdi-magnify"
              clearable
              @input="searchTalent"
              @click:clear="clearSearch()"
            />
          </v-card>
        </v-flex>
        <v-flex
          xs12
        >
          <v-card
            flat
            color="white"
            width="100%"
          >
            <v-card-text class="text-center black--text py-3">
              <span class="text-h5"> {{ $t('all_hosts') }}</span>
              <v-chip
                class="white--text text--opacity-25 text-body-2 ml-4"
                color="#ADADAD"
                :ripple="false"
                small
              >
                {{ totalTalentCount }}
              </v-chip>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-row>
      <v-container>
        <v-row
          no-gutters
          justify="center"
          class="mt-5"
        >
          <template v-if="loading">
            <v-slide-item
              v-for="n in 8"
              :key="n"
            >
              <v-skeleton-loader
                class="ma-2"
                width="221"
                type="card-avatar"
              />
            </v-slide-item>
          </template>
          <template
            v-else
          >
            <v-col
              v-for="(talent, index) in paginatedTalents"
              :key="index"
              xs="6"
              md="auto"
            >
              <v-card
                flat
                class="ma-2"
                rounded="lg"
                :ripple="false"
                @click="showTalentInfo(talent)"
              >
                <v-img
                  :src="talent.user.profileImg ? talent.user.profileImg : $defaultImg"
                  class="white--text"
                  eager
                  width="221"
                  height="230"
                  lazy-src="@/assets/images/default.png"
                  alt="default"
                  gradient="rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)"
                >
                  <v-card-text
                    v-if="talent.featured"
                    class="text-right px-0 py-1"
                  >
                    <v-chip
                      class="white--text text-body-1"
                      label
                      x-small
                      color="primary"
                    >
                      {{ $t('featured') }}
                    </v-chip>
                  </v-card-text>
                  <v-card-text class="host-info black--text px-5">
                    <p class="ma-1 white--text text-h5">
                      {{ talent.user.firstName }} {{ talent.user.lastName }}
                    </p>
                    <p class="ma-1 white--text pt-1 text-subtitle-1">
                      {{ talent.user.email }}
                    </p>
                  </v-card-text>
                </v-img>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-container>
      <v-navigation-drawer
        v-if="isShowTalentInfo"
        v-model="isShowTalentInfo"
        temporary
        app
        right
        width="100%"
        overlay-opacity="90"
      >
        <v-row
          no-gutters
          class="fill-height"
        >
          <v-flex
            xs3
            class="black text-right"
            pa-5
          >
            <v-btn
              color="white"
              small
              width="40"
              height="40"
              @click.stop="isShowTalentInfo = !isShowTalentInfo"
            >
              <v-icon
                dark
                color="black"
              >
                mdi-close
              </v-icon>
            </v-btn>
          </v-flex>
          <v-flex 
            xs9 
            class="grey_regular"
            py-5
            px-10
          >
            <v-row
              no-gutters
              class="fill-height"
            >
              <v-flex
                xs12
                md5
                pa-2
              >
                <v-card 
                  v-if="loading"
                  flat 
                  class="rounded"
                  color="grey_regular"
                >
                  <v-skeleton-loader
                    type="image"
                  />
                  <v-skeleton-loader
                    class="py-5"
                    type="text"
                  />
                  <v-skeleton-loader
                    class="row pl-2"
                    type="chip@3"
                  />
                  <v-skeleton-loader
                    class="py-5"
                    type="text"
                  />
                  <v-card-text class="px-0">
                    <v-row>
                      <v-col
                        md="6"
                        xs="12"
                      >
                        <v-card
                          class="rounded"
                          color="white"
                          tile
                          elevation="0"
                          height="100"
                        >
                          <v-card-text class="py-5">
                            <div class="mb-2 text-subtitle-2 text--opacity-75">
                              {{ $t('service_fee') }}
                              <v-skeleton-loader
                                class="row pt-8"
                                type="heading, button"
                              />
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col
                        md="6"
                        xs="12"
                      >
                        <v-card
                          class="rounded"
                          color="white"
                          tile
                          elevation="0"
                          height="100"
                        >
                          <v-card-text class="py-5">
                            <div class="mb-2 text-subtitle-2 text--opacity-75">
                              {{ $t('featured_host') }}
                            </div>
                            <v-skeleton-loader
                              type="heading"
                            />
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-skeleton-loader
                    class="text-center row justify-center mt-5"
                    type="button"
                  />
                </v-card>
                <v-card 
                  v-else
                  flat 
                  class="rounded"
                  color="grey_regular"
                >
                  <v-img
                    class="rounded"
                    :src="talentInfo.user.profileImg ? talentInfo.user.profileImg : $defaultImg"
                    eager
                    height="400"
                    lazy-src="@/assets/images/default.png"
                    alt="default"
                    gradient="rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)"
                  >
                    <v-card-text
                      v-if="talentInfo.featured"
                      class="text-right px-0 py-1"
                    >
                      <v-chip
                        class="white--text text-body-1"
                        label
                        x-small
                        color="primary"
                      >
                        {{ $t('featured') }}
                      </v-chip>
                    </v-card-text>
                  </v-img>
                  <v-card-text class="black--text text-h5 px-0">
                    {{ talentInfo.user.firstName }} {{ talentInfo.user.lastName }}
                  </v-card-text>
                  <v-card-text 
                    v-if="talentInfo.areas.length"
                    class="black--text px-0 py-0"
                  >
                    <v-chip
                      v-for="area in talentInfo.areas"
                      :key="area.id"
                      class="mb-2 mr-2 text-subtitle-1"
                      color="dark_grey"
                      :ripple="false"
                    >
                      {{ area.name }}
                    </v-chip>
                  </v-card-text>
                  <v-card-text 
                    v-if="address"
                    class="black--text px-1 row align-center"
                  >
                    <img
                      class="d-inline"
                      src="@/assets/images/icons/location.svg"
                      height="25"
                      width="25"
                    > <span class="text-subtitle-2 text--opacity-75">{{ address }}</span> 
                  </v-card-text>
                  <v-card-text class="px-0">
                    <v-row>
                      <v-col
                        md="6"
                        xs="12"
                      >
                        <v-card
                          class="rounded"
                          color="white"
                          tile
                          elevation="0"
                          height="100"
                        >
                          <v-card-text class="py-5">
                            <div class="mb-2 text-subtitle-2 text--opacity-75">
                              {{ $t('service_fee') }}
                              <v-btn
                                v-if="!editServiceFee"
                                class="float-right mt-n3"
                                color="black"
                                icon
                                small
                                :ripple="false"
                                @click="editServiceFee = true"
                              >
                                <v-icon color="black">
                                  mdi-pencil-outline
                                </v-icon>
                              </v-btn>
                            </div>
                            <v-row
                              v-if="editServiceFee"
                              no-gutters
                            >
                              <v-flex
                                xs12
                                sm6
                              >
                                <v-text-field
                                  v-model="talentInfo.serviceFee"
                                  flat
                                  solo
                                  tile
                                  hide-details
                                  color="black"
                                  class="text-h3 mt-n1"
                                />
                              </v-flex>
                              <v-flex
                                xs12
                                sm6
                              >
                                <v-btn
                                  right
                                  color="primary"
                                  width="40"
                                  height="40"
                                  :loading="serviceFeeLoading"
                                  @click.stop="updateServiceFee()"
                                >
                                  Save
                                </v-btn>
                              </v-flex>
                            </v-row>
                            <p 
                              v-else
                              class="text-h3 black--text mb-2"
                            >
                              {{ talentInfo.serviceFee }}%
                            </p>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col
                        md="6"
                        xs="12"
                      >
                        <v-card
                          class="rounded"
                          color="white"
                          tile
                          elevation="0"
                          height="100"
                        >
                          <v-card-text class="py-5">
                            <div class="mb-2 text-subtitle-2 text--opacity-75">
                              {{ $t('featured_host') }}
                            </div>
                            <v-switch
                              v-model="talentInfo.featured"
                              color="primary"
                              :ripple="false"
                              hide-details
                              :false-value="false"
                              :true-value="true"
                              @change="setFeaturedHost()"
                            />
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-text class="text-center mt-5">
                    <v-btn
                      color="white"
                      :ripple="false"
                      :loading="deleteHostLoading"
                      @click="deleteDialog = true"
                    >
                      {{ $t('delete_host') }}
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex
                xs12
                md7
                pa-3
                px-8
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    xs="12"
                  >
                    <v-card
                      class="pa-2 rounded"
                      color="white"
                      tile
                      elevation="0"
                    >
                      <v-card-text>
                        <div class="mb-2 text-subtitle-2 text--opacity-75">
                          {{ $t('total_earned') }}
                        </div>
                        <v-skeleton-loader
                          v-if="loading"
                          type="heading"
                        />
                        <template v-else>
                          <p 
                            class="text-h3 black--text mb-2"
                          >
                            ${{ totalEarned }}
                          </p>
                          <p 
                            class="text-body-2 text--opacity-50 mb-0"
                          >
                            {{ $t('last_month') }}: ${{ earnedLastmonth }}
                          </p>
                        </template>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    xs="12"
                  >
                    <v-card
                      class="pa-2 rounded"
                      color="white"
                      tile
                      elevation="0"
                    >
                      <v-card-text>
                        <div class="mb-2 text-subtitle-2 text--opacity-75">
                          {{ $t('total_service_fees') }}
                        </div>
                        <v-skeleton-loader
                          v-if="loading"
                          type="heading"
                        />
                        <template v-else>
                          <p 
                            class="text-h3 black--text mb-2"
                          >
                            {{ totalServiceFee }}
                          </p>
                          <p 
                            class="text-body-2 text--opacity-50 mb-0"
                          >
                            {{ $t('last_month') }}: ${{ serviceFeeLastmonth }}
                          </p>
                        </template>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    xs="12"
                  >
                    <v-card
                      class="pa-2 rounded"
                      color="white"
                      tile
                      elevation="0"
                    >
                      <v-card-text>
                        <div class="mb-2 text-subtitle-2 text--opacity-75">
                          {{ $t('upc_session') }}
                        </div>
                        <v-skeleton-loader
                          v-if="loading"
                          type="heading"
                        />
                        <p 
                          v-else
                          class="text-h3 black--text mb-2"
                        >
                          {{ upcomingSessions }}
                        </p>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    xs="12"
                  >
                    <v-card
                      class="pa-2 rounded"
                      color="white"
                      tile
                      elevation="0"
                    >
                      <v-card-text>
                        <div class="mb-2 text-subtitle-2 text--opacity-75">
                          {{ $t('sessions_completed') }}
                        </div>
                        <v-skeleton-loader
                          v-if="loading"
                          type="heading"
                        />
                        <p 
                          v-else
                          class="text-h3 black--text mb-2"
                        >
                          {{ completedSessions }}
                        </p>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-card
                      class="payment-card rounded"
                      color="white"
                      tile
                      elevation="0"
                    >
                      <v-card-title class="text-h5">
                        {{ $t('payment_history') }}
                      </v-card-title>
                      <v-card-text class="grey_medium payment-inner-card">
                        <template v-if="loading">
                          <v-skeleton-loader
                            v-for="n in 10"
                            :key="n"
                            type="list-item-two-line"
                          />
                        </template>
                        <v-list 
                          v-else
                          two-line
                          class="grey_medium"
                        >
                          <template v-for="payment in payouts">
                            <v-list-item
                              :key="payment.payoutid"
                              class="px-0"
                            >
                              <v-list-item-content>
                                <v-list-item-title
                                  class="text-subtitle-2 text--opacity-75"
                                  v-text="`${payment.paidBy.firstName} ${payment.paidBy.lastName}`"
                                />
                                <v-list-item-subtitle
                                  class="text-subtitle-2 text--opacity-50"
                                  v-text="getTimeAgoDate(payment.createdOn)"
                                />
                              </v-list-item-content>

                              <v-list-item-action>
                                <v-list-item-action-text
                                  class="subtitle-1"
                                  v-text="`+$${payment.charge}`"
                                />
                              </v-list-item-action>
                            </v-list-item>
                          </template>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-flex>
            </v-row>
          </v-flex>
        </v-row>
        <v-dialog
          v-if="deleteDialog"
          v-model="deleteDialog"
          persistent
          max-width="400"
        >
          <v-card
            class="rounded-lg"
          >
            <v-card-title class="text-left text-h6">
              {{ $t('host_delete_txt') }}
            </v-card-title>
            <v-card-text class="text-left text-subtitle-2 ma-0 text--opacity-50">
              {{ $t('action_undone') }}
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="black"
                text
                @click="deleteDialog = false"
              >
                {{ $t("no") }}
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteHost()"
              >
                {{ $t("yes") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-navigation-drawer>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// utils
import { delay } from "@/utils/helpers";
import i18n  from '@/utils/i18n'


// graphql
import { GET_ALL_TALENT, SEARCH_TALENT, GET_TALENT_DASHBOARD } from "@/graphql/talent";
import { UPDATE_SERVICE_FEE, DELETE_USER, SET_FEATURED_HOST, GET_HOST_INFO } from "@/graphql/admin";

export default {
  data() {
    return {
      sidebarMenu: true,
      toggleMini: false,
      loading: false,
      isShowTalentInfo: false,
      serviceFeeLoading: false,
      deleteHostLoading: false,
      deleteDialog: false,
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard", url: "/admin/dashboard" },
        { title: "Sessions", icon: "mdi-clock", url: "/admin/sessions" },
        { title: "Hosts", icon: "mdi-calendar-blank", url: "/admin/hosts" },
        { title: "Users", icon: "mdi-help-circle-outline", url: "/admin/users" },
      ],
      talentInfo: null,
      totalEarned: 0,
      earnedLastmonth: 0,
      totalServiceFee: 0,
      serviceFeeLastmonth: 0,
      totalPayouts: 0,
      upcomingSessions: 0,
      completedSessions: 0,
      payouts: [],
      editServiceFee: false,
      paginatedTalents: [],
      first:12,
      skip:0,
      totalTalentCount: 0,
      records: 0,
    }
  },
  computed: {
    ...mapGetters([ 'isLoggedIn', 'user', 'jwt' ]),
    mini() {
      return (this.$vuetify.breakpoint.smAndDown) || this.toggleMini
    },
    getTimeAgoDate(){
      return date => this.$moment(this.$moment(date).toISOString()).tz(this.$moment.tz.guess(Boolean)).fromNow()
    },
    address(){
      return this.talentInfo && this.talentInfo.user.country &&  this.talentInfo.user.city ? `${this.talentInfo.user.city}, ${this.talentInfo.user.country.name}` : null;
    },
  },
  mounted() {
    this.getAllTalent()
    this.loadOnScroll()
  },
  methods:{
    /**
     * Load More Talent On Scroll
     */
    loadOnScroll(){
      window.onscroll = async() => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
        if (bottomOfWindow && (this.records != this.paginatedTalents.length)) {
          this.skip += this.first
          await this.getAllTalent(true)
        }
      }
    },
    /**
     * Get Talent Lists
     */
    async getAllTalent(isScroll = false) {
      this.loading = !isScroll
      const { data: { getTalentList } } = await this.$apollo.query({
        query: GET_ALL_TALENT,
        fetchPolicy:'no-cache',
        variables: {
          pagination:{
            first: this.first,
            skip: this.skip,
          },
        },
      });
      if (getTalentList.success) {
        this.paginatedTalents.push(...getTalentList.talentList)
        this.totalTalentCount = getTalentList.totalRecords
        this.records = getTalentList.totalRecords
      }
      this.loading = false;
    },

    /**
     * Search Talent
     */
    async searchTalent(keyword) {
      if(keyword){
        this.loading = true
        this.first = 12,
        this.skip = 0,
        this.paginatedTalents = []
        this.records = 0
        const {
          data: { searchTalent },
        } = await this.$apollo.query({
          query: SEARCH_TALENT,
          variables: {
            searchInput: {
              name: keyword,
              pagination:{
                first: this.first,
                skip: this.skip,
              },
            },
          },
        });
    
        if (searchTalent.talentList) {
          this.paginatedTalents.push(...searchTalent.talentList)
          this.records = searchTalent.totalRecords
        } 
        this.loading = false
      } else {
        this.clearSearch()
      }
    },

    /**
     * Clear Search
     */
    async clearSearch(){
      this.records = 0
      this.first = 12,
      this.skip = 0,
      this.totalTalentCount = 0
      this.paginatedTalents = []
      await this.getAllTalent()
    },

    /**
     *  Get And Show Talent Info
     */

    async showTalentInfo(talent){
      this.isShowTalentInfo = true 
      this.loading = true
      try {
        const { data: { getHostInfo } } = await this.$apollo.query({
          query: GET_HOST_INFO,
          fetchPolicy: 'no-cache',
          variables: {
            hostInput: { 
              email: this.user.email,
              accesstoken: this.jwt,
              username: talent.user.username,
            },
          },
        });

        if (getHostInfo.success) {
          this.totalEarned         = getHostInfo.totalEarned
          this.earnedLastmonth     = getHostInfo.earnedLastmonth
          this.totalServiceFee     = getHostInfo.totalServiceFee
          this.serviceFeeLastmonth = getHostInfo.serviceFeeLastmonth
          this.totalPayouts        = getHostInfo.totalPayouts
          this.upcomingSessions    = getHostInfo.upcomingSessions
          this.completedSessions   = getHostInfo.completedSessions
          this.payouts             = getHostInfo.payouts
          this.talentInfo          = getHostInfo.host
          this.loading             = false
        } else {
          this.$toasted.error(this.$t(getHostInfo.error))
          this.loading = false
          this.isShowTalentInfo = false 
        }
      } catch (e) {
        console.error(e); // eslint-disable-line
        this.loading = false
      }
      this.loading = false
    },

    /**
     * Update Service Fee
     */
    async updateServiceFee(){
      if (this.talentInfo.serviceFee) {
        this.serviceFeeLoading = true
        try {
          const { data: { updateServiceFee } } = await this.$apollo.mutate({
            mutation: UPDATE_SERVICE_FEE,
            variables: {
              serviceData: { 
                email: this.user.email,
                accesstoken: this.jwt,
                talentEmail: this.talentInfo.user.email,
                serviceFee: this.talentInfo.serviceFee,
              },
            },
          });
  
          if (updateServiceFee.success) {
            this.$toasted.success(i18n.t('service_fee_update_txt'))
            this.serviceFeeLoading = false
            this.editServiceFee = !this.editServiceFee
          } else {
            this.$toasted.error(this.$t(updateServiceFee.error))
            this.serviceFeeLoading = false
          }
        } catch (e) {
          console.error(e); // eslint-disable-line
          this.serviceFeeLoading = false
        }
      }
    },

    /**
     * Delete Host
     */
    async deleteHost(){
      this.deleteDialog = false
      this.deleteHostLoading = true
      try {
        const { data: { deleteUser } } = await this.$apollo.mutate({
          mutation: DELETE_USER,
          variables: {
            userData: { 
              accesstoken: this.jwt,
              email: this.user.email,
              userEmail: this.talentInfo.user.email,
            },
          },
        });

        if (deleteUser.success) {
          this.$toasted.success(i18n.t('host_delete_success_txt'))
          this.deleteHostLoading = false
          this.isShowTalentInfo = false 
          this.clearSearch()
        } else {
          this.passwordError = this.$t(deleteUser.error)
          this.deleteHostLoading = false
        }
      } catch (e) {
        console.error(e); // eslint-disable-line
        this.deleteHostLoading = false
      }
    },

    /**
     * Set Featured Host
     */
    async setFeaturedHost(){
      try {
        const { data: { setFeaturedHost } } = await this.$apollo.mutate({
          mutation: SET_FEATURED_HOST,
          variables: {
            featuredData: { 
              email: this.user.email,
              accesstoken: this.jwt,
              userEmail: this.talentInfo.user.email,
            },
          },
        });

        if (!setFeaturedHost.success) {
          this.talentInfo.featured = !this.talentInfo.featured
          this.$toasted.error(this.$t(setFeaturedHost.error))
        } 
      } catch (e) {
        console.error(e); // eslint-disable-line
      }
    },
    /**
     * Logout
     */
    async logout() {
      await this.$store.dispatch("logout");
      window.location = "/";
    },
  },
}
</script>
<style lang="scss" scoped>
  #admin-hosts {
    min-height: calc(100vh - 75px);
    background-color: #F1F3F6;
  }
  .dashboard-container {
    margin-top: 9px;
  }
  .payment-card {
    height: calc(100vh - 370px);
  }
  .payment-inner-card {
    height: calc(100vh - 370px);
    overflow-y: auto;
  }
  .host-info {
    position: absolute;
    bottom: 0;
  }
</style>

<style lang="scss">
  .payment-inner-card .v-skeleton-loader .v-skeleton-loader__list-item-two-line.v-skeleton-loader__bone {
    background: none !important;
  }
</style>
